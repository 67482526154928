<template>
  <section class="hero-section">
    <div class="hero-content">
      <h1 class="name">{{ name }}</h1>
      <h2 class="title">{{ title }}</h2>
    </div>
    <div class="scroll-arrow" @click="scrollDown">
      ↓
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      name: 'Brian Mulei',
      title: 'Fullstack Software Engineer',
    };
  },
  methods: {
    scrollDown() {
      window.scrollTo({
        top: window.innerHeight,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped>
.hero-section {
  height: 100vh;
  width: 100%;
  background: url('../assets/skynight_1.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero-content {
  text-align: center;
  animation: zoomIn 2s ease-in-out;
}

.name {
  font-size: 4rem;
  font-weight: bold;
  color: white;
}

.title {
  font-size: 2rem;
  color: white;
  margin-top: 10px;
}

/* Hover effect with purple-blue gradient glow */
 

.scroll-arrow {
  position: absolute;
  bottom: 20px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  animation: bounce 2s infinite;
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}
</style>
