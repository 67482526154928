<template>
  <v-app>
    <v-main>

      <div id="app">
    <!-- Sticky Navigation Bar -->


    <router-view />
  </div>
      <!-- <router-view/> -->
     </v-main>
  </v-app>
</template>

<script>
 
export default {
  name: 'App',

  data() {
    return {
      menuActive: false, // Menu active state for mobile
    };
  },
  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive; // Toggle the menu on mobile
    },
    scrollTo(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
      this.menuActive = false; // Close the menu after clicking on mobile

    }
  }

}
</script>
<style>
/* html,
body {
  background: linear-gradient(180deg, #000000, #1A237E);
  height: 100%;
  margin: 0;
  padding: 0;
} */
/* General Navbar Styles */

</style>